export default {
    apiKey: "AIzaSyD8uDn1iqsNcN89EEDju0KkgZPjGunGAS8",
    authDomain: "checkyoulater-bd5fa.firebaseapp.com",
    databaseURL: "https://checkyoulater-bd5fa.firebaseio.com",
    projectId: "checkyoulater-bd5fa",
    storageBucket: "",
    messagingSenderId: "219505305120",
    appId: "1:219505305120:web:8f29cfd31c7f45e7"
  };

