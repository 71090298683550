/*---------------Modal-----------------------*/
export const SET_MODAL = 'SET_MODAL'
export const REMOVE_MODAL = 'REMOVE_MODAL'
/*---------------Bookmark--------------------*/
export const GET_BOOKMARKS = 'GET_BOOKMARKS'
export const ADD_BOOKMARK = 'ADD_BOOKMARK'
export const ADD_CATEGORY = 'ADD_CATEGORY'
export const UPDATE_BOOKMARK = 'UPDATE_BOOKMARK'
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'
export const DELETE_BOOKMARK = 'DELETE_BOOKMARK'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const BOOKMARK_ERR = 'BOOKMARK_ERR'
export const SORT_CATEGORIES = 'SORT_CATEGORIES'
export const FILTER_CATEGORIES = 'FILTER_CATEGORIES'
export const CLEAR_FILTER = 'CLEAR_FILTER'
export const ADJUST_SIZE = 'ADJUST_SIZE'
export const LOCAL_TO_DB = 'LOCAL_TO_DB'
/*---------------Auth------------------------*/
export const AUTHORIZING= 'AUTHORIZING'
export const AUTHORIZING_2 = 'AUTHORIZING_2'
export const USER_LOADED = 'USER_LOADED'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
